<template>
  <div v-if="scrollPickerMode" class="flex">
    <template v-if="isUSDateFormat">
      <WebScrollPicker
        v-model="activeMonth"
        :label="translate('generate.label.month', locale)"
        :label-style="labelStyle"
        :hide-label="hideLabels"
        class="max-w-[125px] flex-1"
        :options="mappedMonthOptions"
        :page-options="pageOptions"
        :placeholder="translate('generate.placeholder.selectMonth', locale)"
      />
      <WebScrollPicker
        v-model="activeDay"
        :label="translate('generate.label.day', locale)"
        :label-style="labelStyle"
        :hide-label="hideLabels"
        class="max-w-[125px] flex-1"
        :options="dayOptions"
        :page-options="pageOptions"
        :placeholder="translate('generate.placeholder.selectDay', locale)"
      />
    </template>
    <template v-else>
      <WebScrollPicker
        v-model="activeDay"
        :label="translate('generate.label.day', locale)"
        :label-style="labelStyle"
        :hide-label="hideLabels"
        class="max-w-[125px] flex-1"
        :options="dayOptions"
        :page-options="pageOptions"
        :placeholder="translate('generate.placeholder.selectDay', locale)"
      />
      <WebScrollPicker
        v-model="activeMonth"
        :label="translate('generate.label.month', locale)"
        :label-style="labelStyle"
        :hide-label="hideLabels"
        class="max-w-[125px] flex-1"
        :options="mappedMonthOptions"
        :page-options="pageOptions"
        :placeholder="translate('generate.placeholder.selectMonth', locale)"
      />
    </template>
    <WebScrollPicker
      v-model="activeYear"
      :label="translate('generate.label.year', locale)"
      :label-style="labelStyle"
      :hide-label="hideLabels"
      class="max-w-[125px] flex-1"
      :options="yearOptions"
      :page-options="pageOptions"
      :placeholder="translate('generate.placeholder.selectYear', locale)"
    />
  </div>
  <div v-else class="tw-date-select mb-16 flex justify-center">
    <div class="flex flex-1 gap-8" :class="[isVertical ? 'flex-col items-center' : 'flex-row items-end']">
      <template v-if="isUSDateFormat">
        <WebSelect
          v-model="activeMonth"
          name="month"
          :class="selectClass"
          :placeholder="showMonthNames ? translate('generate.label.month', locale) : translate('generate.placeholder.month', locale)"
          :options="mappedMonthOptions"
          :page-options="pageOptions"
          :label="translate('generate.label.month', locale)"
          :label-style="labelStyle"
          :hide-label="hideLabels"
          hide-selected-option-check
          :error="hasError"
        />
        <WebSelect
          v-model="activeDay"
          name="day"
          :class="selectClass"
          :placeholder="translate('generate.placeholder.day', locale)"
          :options="dayOptions"
          :page-options="pageOptions"
          :label="translate('generate.label.day', locale)"
          :label-style="labelStyle"
          :hide-label="hideLabels"
          hide-selected-option-check
          :error="hasError"
        />
      </template>
      <template v-else>
        <WebSelect
          v-model="activeDay"
          name="day"
          :class="selectClass"
          :placeholder="translate('generate.placeholder.day', locale)"
          :options="dayOptions"
          :page-options="pageOptions"
          :label="translate('generate.label.day', locale)"
          :label-style="labelStyle"
          :hide-label="hideLabels"
          hide-selected-option-check
          :error="hasError"
        />
        <WebSelect
          v-model="activeMonth"
          name="month"
          :class="selectClass"
          :placeholder="showMonthNames ? translate('generate.label.month', locale) : translate('generate.placeholder.month', locale)"
          :options="mappedMonthOptions"
          :page-options="pageOptions"
          :label="translate('generate.label.month', locale)"
          :label-style="labelStyle"
          :hide-label="hideLabels"
          hide-selected-option-check
          :error="hasError"
        />
      </template>
      <WebSelect
        v-model="activeYear"
        name="year"
        :class="selectClass"
        :placeholder="translate('generate.placeholder.year', locale)"
        :label="translate('generate.label.year', locale)"
        :options="yearOptions"
        :page-options="pageOptions"
        :label-style="labelStyle"
        :hide-label="hideLabels"
        hide-selected-option-check
        :error="hasError"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, computed, ref, toRefs, watch, onBeforeMount } from 'vue';
import { WebSelect, WebScrollPicker } from '@shared/components';
import { useTranslate } from '@shared/composable/useTranslate';
import type { PageOptions } from '@shared/types/model';
import { composeDate, fillDayOptions, useDateSelectOptionsWithInterval } from '../utils';
import { getDaysInMonth } from 'date-fns';

const props = defineProps({
  modelValue: { type: String, default: '' },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  locale: { type: String, default: '' },
  labelStyle: { type: Object, default: undefined },
  hideLabels: { type: Boolean, default: false },
  minDate: { type: String, default: '' },
  maxDate: { type: String, default: '' },
  hasError: { type: Boolean, default: false },
  direction: { type: String as PropType<'horizontal' | 'vertical'>, default: 'horizontal' },
  scrollPickerMode: { type: Boolean, default: false },
  showMonthNames: { type: Boolean, default: true },
  isUSDateFormat: { type: Boolean, default: true }
});

const emit = defineEmits(['update:modelValue']);

const { translate } = useTranslate();
const { day, month, year } = composeDate(props.modelValue);
const selectedDay = ref(day);
const selectedMonth = ref(month);
const selectedYear = ref(year);
const dayOptions = ref();
const dayCount = ref(31);
const { minDate, maxDate } = toRefs(props);
const { monthOptions, yearOptions } = useDateSelectOptionsWithInterval({ minDate, maxDate });

const isVertical = computed(() => props.direction === 'vertical');
const selectClass = computed(() => ['!mb-0 flex-1', isVertical.value ? '!min-w-full' : '!min-w-[50px]']);
const mappedMonthOptions = computed(() => {
  if (!props.showMonthNames) return monthOptions.value;
  return monthOptions.value.map(item => {
    const { label, value } = item;
    return {
      label: translate(`generate.monthLabel.${label}`, props.locale),
      value
    }
  })
});

const activeDay = computed({
  get() {
    fetchDayOptions();
    const { day } = composeDate(props.modelValue);
    return day || selectedDay.value;
  },
  set(value) {
    selectedDay.value = value;
    updateModelValue();
  }
});

const activeMonth = computed({
  get() {
    const { month } = composeDate(props.modelValue);
    return month || selectedMonth.value;
  },
  set(value) {
    selectedMonth.value = value;
    updateModelValue();
  }
});

const activeYear = computed({
  get() {
    const { year } = composeDate(props.modelValue);
    return year || selectedYear.value;
  },
  set(value) {
    selectedYear.value = value;
    updateModelValue();
  }
});

const fullDate = computed(() => {
  if (!selectedDay.value || !selectedMonth.value || !selectedYear.value) return '';
  return [selectedDay.value, selectedMonth.value, selectedYear.value].join('-');
});

function updateModelValue() {
  if (selectedMonth.value && selectedYear.value) {
    dayCount.value = getDaysInMonth(new Date(+selectedYear.value, +selectedMonth.value - 1));
  }
  if (+selectedDay.value > dayCount.value) {
    selectedDay.value = '';
    return emit('update:modelValue', '');
  }
  return emit('update:modelValue', fullDate.value);
}

function fetchDayOptions() {
  dayOptions.value = fillDayOptions({ minDate: props.minDate, maxDate: props.maxDate, dayCount: dayCount.value });
}

watch(
  () => selectedMonth.value,
  (newValue) => {
    if (newValue && selectedYear.value) {
      fetchDayOptions();
    }
  }
);

watch(
  () => selectedYear.value,
  (newValue) => {
    if (selectedMonth.value && newValue) {
      fetchDayOptions();
    }
  }
);

onBeforeMount(() => {
  fetchDayOptions();
});
</script>

<style lang="postcss" scoped>
:deep(.tw-selections__text) {
  @apply text-neutral-900;
}
</style>
